import React, { useState } from 'react';
import './Formulario.css';

export default function Formulario() {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    phone: '',
    email: '',
    state: '',
    city: '',
    postalCode: '',
    businessType: '',  // Mover este campo al primer formulario
    businessName: '',
    monthlyExpenses: '',
    supplySource: '',
    video: null,
    terms: false,
    privacy: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'file' ? files[0] : type === 'checkbox' ? checked : value
    }));
  };

  // Enviar datos del primer formulario
  const handleFirstFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://saborrenovadoufs.com.mx/api/form1', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: formData.name,
          lastname: formData.lastname,
          phone: formData.phone,
          email: formData.email,
          state: formData.state,
          city: formData.city,
          postalCode: formData.postalCode,
          businessType: formData.businessType, // Enviar tipo de negocio
        })
      });

      const result = await response.text();

      if (result === 'Correo ya registrado') {
        console.log('El correo ya está registrado, se actualizarán los datos.');
      } else {
        console.log('Registro exitoso del Formulario 1');
      }

      // Pasar al segundo paso
      setStep(2);

    } catch (error) {
      console.error('Error al enviar el Formulario 1:', error);
    }
  };

  // Enviar datos del segundo formulario
  const handleSecondFormSubmit = async (e) => {
    e.preventDefault();

    const form2Data = new FormData();
    form2Data.append('businessName', formData.businessName);
    form2Data.append('monthlyExpenses', formData.monthlyExpenses);
    form2Data.append('supplySource', formData.supplySource);
    form2Data.append('video', formData.video);
    form2Data.append('email', formData.email);  // Añadir el correo electrónico

    try {
      const response = await fetch('https://saborrenovadoufs.com.mx/api/form2', {
        method: 'POST',
        body: form2Data
      });

      if (response.ok) {
        console.log('Formulario 2 enviado correctamente');
        setStep(3);
      } else {
        console.log('Error al enviar el Formulario 2');
      }
    } catch (error) {
      console.error('Error al enviar el Formulario 2:', error);
    }
  };

  return (
    <div id='formulario' className="row">
      <div className="col-12 text-center mb-4 mt-2 unete">
        <h1>¡Únete a nuestro concurso!</h1>
      </div>
      <h2 style={{ textAlign: 'center'}}>¡Regístrate ya!</h2>
      <div className="col-12 col-md-6" style={{ alignContent: 'center', textAlign: 'center'}}>
        <img className='formulario' src="/media/premios-3.jpg" alt="Descripción de la imagen" />
      </div>
      <div className="col-12 col-md-6" style={{ alignContent: 'center' }}>
        <div className="form-container">
          {step === 1 && (
            <form onSubmit={handleFirstFormSubmit}>
              <div className="form-group">
                <label htmlFor="name">Nombre</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="name">Apellido</label>
                <input type="text" id="lastname" name="lastname" value={formData.lastname} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="phone">Teléfono</label>
                <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Correo</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="state">Estado</label>
                <select id="state" name="state" value={formData.state} onChange={handleChange} required>
                  <option value="">Seleccione un estado</option>
                  <option value="Aguascalientes">Aguascalientes</option>
                  <option value="Baja California">Baja California</option>
                  <option value="Baja California Sur">Baja California Sur</option>
                  <option value="Campeche">Campeche</option>
                  <option value="Chiapas">Chiapas</option>
                  <option value="Chihuahua">Chihuahua</option>
                  <option value="Coahuila">Coahuila</option>
                  <option value="Colima">Colima</option>
                  <option value="Durango">Durango</option>
                  <option value="Guanajuato">Guanajuato</option>
                  <option value="Guerrero">Guerrero</option>
                  <option value="Hidalgo">Hidalgo</option>
                  <option value="Jalisco">Jalisco</option>
                  <option value="Mexico">Estado de México</option>
                  <option value="Mexico City">Ciudad de México</option>
                  <option value="Michoacán">Michoacán</option>
                  <option value="Morelos">Morelos</option>
                  <option value="Nayarit">Nayarit</option>
                  <option value="Nuevo León">Nuevo León</option>
                  <option value="Oaxaca">Oaxaca</option>
                  <option value="Puebla">Puebla</option>
                  <option value="Querétaro">Querétaro</option>
                  <option value="Quintana Roo">Quintana Roo</option>
                  <option value="San Luis Potosí">San Luis Potosí</option>
                  <option value="Sinaloa">Sinaloa</option>
                  <option value="Sonora">Sonora</option>
                  <option value="Tabasco">Tabasco</option>
                  <option value="Tamaulipas">Tamaulipas</option>
                  <option value="Tlaxcala">Tlaxcala</option>
                  <option value="Veracruz">Veracruz</option>
                  <option value="Yucatán">Yucatán</option>
                  <option value="Zacatecas">Zacatecas</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="city">Ciudad</label>
                <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="postalCode">Código Postal</label>
                <input type="text" id="postalCode" name="postalCode" value={formData.postalCode} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="businessType">Tipo de Negocio</label>
                <select id="businessType" name="businessType" value={formData.businessType} onChange={handleChange} required>
                  <option value="">Seleccione un tipo de negocio</option>
                  <option value="Abarrotes">Abarrotes</option>
                  <option value="Banquetes">Banquetes</option>
                  <option value="Bar/Cantina">Bar/Cantina</option>
                  <option value="Cafetería">Cafetería</option>
                  <option value="Club de precios">Club de precios</option>
                  <option value="Comedor">Comedor</option>
                  <option value="CPU">CPU</option>
                  <option value="Distribuidor">Distribuidor</option>
                  <option value="Fonda">Fonda</option>
                  <option value="Hotel">Hotel</option>
                  <option value="Mayorista">Mayorista</option>
                  <option value="Restaurante Americano">Restaurante Americano (pizzas, hotdogs, alitas, etc.)</option>
                  <option value="Restaurante Internacional">Restaurante Internacional</option>
                  <option value="Restaurante Mariscos">Restaurante Mariscos</option>
                  <option value="Restaurante Mexicano">Restaurante Mexicano</option>
                  <option value="Taquería">Taquería</option>
                  <option value="Tortería">Tortería</option>
                  <option value="Otro">Otro</option>
                </select>
              </div>
              <div className="checkbox-group">
                <input type="checkbox" id="terms" name="terms" checked={formData.terms} onChange={handleChange} required />
                <label htmlFor="terms">
                  Acepto los <a href="/terminos-condiciones" target="_blank" rel="noopener noreferrer">Términos y condiciones</a>
                </label>
              </div>
              <div className="checkbox-group">
                <input type="checkbox" id="privacy" name="privacy" checked={formData.privacy} onChange={handleChange} required />
                <label htmlFor="privacy">
                  Acepto el <a href="https://www.unilevernotices.com/privacy-notices/mexico-spanish.html" target="_blank" rel="noopener noreferrer">Aviso de privacidad</a>
                </label>
              </div>
              <button type="submit">Siguiente</button>
            </form>
          )}
          {step === 2 && (
            <form onSubmit={handleSecondFormSubmit}>
              {/* Campos del segundo formulario */}
              <div className="form-group">
                <label htmlFor="businessName">Nombre del Negocio</label>
                <input type="text" id="businessName" name="businessName" value={formData.businessName} onChange={handleChange} required />
              </div>
              <div className="form-group">
                <label htmlFor="monthlyExpenses">Gastos Mensuales del Negocio</label>
                <div>
                  <label>
                    <input 
                      type="radio" 
                      name="monthlyExpenses" 
                      value="0 a 4999" 
                      onChange={handleChange} 
                      checked={formData.monthlyExpenses === '0 a 4999'}
                    /> $0 a $4,999
                  </label>
                  <label>
                    <input 
                      type="radio" 
                      name="monthlyExpenses" 
                      value="5000 a 9999" 
                      onChange={handleChange} 
                      checked={formData.monthlyExpenses === '5000 a 9999'}
                    /> $5,000 a $9,999
                  </label>
                  <label>
                    <input 
                      type="radio" 
                      name="monthlyExpenses" 
                      value="10,000 a 14,999" 
                      onChange={handleChange} 
                      checked={formData.monthlyExpenses === '10,000 a 14,999'}
                    /> $10,000 a $14,999
                  </label>
                  <label>
                    <input 
                      type="radio" 
                      name="monthlyExpenses" 
                      value="15,000 o más" 
                      onChange={handleChange} 
                      checked={formData.monthlyExpenses === '15,000 o más'}
                    /> $15,000 o más
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="supplySource">Dónde Compras tus Insumos</label>
                <select id="supplySource" name="supplySource" value={formData.supplySource} onChange={handleChange} required>
                  <option value="">Seleccione una opción</option>
                  <option value="Distribuidores">Distribuidores (la ranita de la paz, food services group, Sigma, Abarrotes Raul)</option>
                  <option value="Club de precio">Club de precio (sams, costco, city market)</option>
                  <option value="Mayoreo">Mayoreo (zorro, Garis, central abasto)</option>
                  <option value="Ecommerce">Ecommerce (amazon, sitios distribuidores en línea)</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="video">Carga de Video</label>
                <input type="file" id="video" name="video" onChange={handleChange} accept="video/*" required />
              </div>
              <button type="submit">Enviar</button>
            </form>
          )}
          {step === 3 && (
            <div>
              <h3>¡Gracias por tu registro!</h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
