import React from 'react'
import './Esenciales.css';
import { Link } from 'react-router-dom';

export default function Esenciales() {
  return (
    <div id='esenciales' className="row">
        <div className="col-12 text-center">
          <Link to="/">
            <img className='banner-p' src="/media/banner.jpg" alt="Descripción de la imagen" />
          </Link>
        </div>
        <div className="col-12 text-center esenciales-bg pt-4">
            <div className='subtitulo'>
              <h2 style={{ background: '#cbc5c7', width: '300px', padding: '10px', borderRadius: '50px'  }}>Esenciales</h2>
            </div>
            <div className="row">
              <div className="col-6 col-md-3">
                <img className='premios' src="/media/e1.png" alt="Descripción de la imagen" />
              </div>
              <div className="col-6 col-md-3">
                <img className='premios' src="/media/e2.png" alt="Descripción de la imagen" />
              </div>
              <div className="col-6 col-md-3">
                <img className='premios' src="/media/e3.png" alt="Descripción de la imagen" />
              </div>
              <div className="col-6 col-md-3">
                <img className='premios' src="/media/e4.png" alt="Descripción de la imagen" />
              </div>
            </div>
        </div>
        <img className='bg-img-e' src="/media/bg1-e.png" alt="Descripción de la imagen" />
    </div>
  )
}
