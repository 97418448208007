// src/page/Home.jsx
import React from 'react';
import Premios from './Premios';
import Jurado from './Jurado';
import Productos from './Productos';
import QuienesSomos from './QuienesSomos';
import Formulario from './Formulario';
import CanalesVenta from './CanalesVenta';
import Esenciales from './Esenciales';

export default function Home() {
  return (
    <>
      <section id='esenciales'>
        <Esenciales />
      </section>
      <section id='formulario'>
        <Formulario />
      </section>
      <section id='premios'>
        <Premios />
      </section>
      <section id='jurado'>
        <Jurado />
      </section>
      <section id='venta'>
        <CanalesVenta />
      </section>
      <section id='productos'>
        <Productos />
      </section>
      <section id='quienes'>
        <QuienesSomos />
      </section>
    </>
  );
}
